<template>
  <div>
    <img
        v-for="(item,index) in imgList "
        :key="index"
        class="sale-img"
        :src="item.src"
        style="max-width:500px"
        alt=""
        @click="link(item.link)"
    >
    <div class="content">
      <div
          style="text-decoration: underline;font-size:16px"
          @click="toPoster"
      >{{ to_poster_text }}
      </div>
      <div
          id="target"
          class="text"
      > {{ copywriting.content }}
      </div>
      <div
          id="target2"
          class="text"
      > {{ spread.link }}
      </div>
      <button
          style="font-size:16px"
          name="button"
          class="button"
          @click="copyLink"
      >{{ copywriting.text }}
      </button>
      <button
          style="font-size:16px"
          name="button"
          class="button"
          @click="copyText"
      >{{ spread.text }}
      </button>
      <div
          style="text-decoration: underline;font-size:16px"
          @click="toIncome"
      >{{ to_income_text }}
      </div>
    </div>
    <div :class="{'tootip':true,'show':isShow}">复制成功</div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      isShow: false,
      title: '',
      // imgList: [{ src: "https://ceshiren.com/uploads/default/original/3X/4/9/4908d9cca3d3e96880902b88d759df46316f7584.png" }, { src: "https://ceshiren.com/uploads/default/original/3X/4/9/4908d9cca3d3e96880902b88d759df46316f7584.png" }],
      // copywriting: { text: '复制内容到剪贴板测试,qweqweqweqweqwe' },
      // spread: { text: '推广文案qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq' },
      // to_income_text: '推广收益查询',
      // to_poster_text: '查看个人海报页',
      // user: {
      //   project: 'demo',
      //   uid: 'start'
      // }
      imgList: [],
      copywriting: {},
      spread: {},
      to_income_text: '',
      to_poster_text: '',
      user: {}
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    window.vue = this;
    console.log(this.title);
    document.title = this.title
  },
  watch: {
    title: {
      handler(newval, oldval) {
        if (newval !== oldval) document.title = this.title
      }
    }
  },
  methods: {
    //跳转到海报页
    toPoster() {
      // console.log(this.user);
      this.$router.push({path: '/poster', query: {project: this.user.project, refer: this.user.uid}})
    },
    //跳转到收益页面
    toIncome() {
      // const queryData = this.$resCommon.reqEncrypt(this.user)
      // console.log('---------', queryData);
      // this.$router.push({path: '/income', query: {data: queryData}})
      this.$router.push({path: '/income', query: {project: this.user.project, uid: this.user.uid}})
    },
    getData() {
      let origin_ = window.location.origin
      // 调试代码 无参数时 使用默认数据 上线前去掉if
      // if (this.$route.query.project) {
      //   this.user.project = this.$route.query.project
      //   this.user.uid = this.$route.query.uid
      // }
      this.user.project = this.$route.query.project
      this.user.uid = this.$route.query.uid
      // 加密发送参数
      // let reqParams = this.$resCommon.reqEncrypt(this.user)
      // console.log('查看请求数据', this.user);
      // axios.post(`${origin_}/backend/sale`, {data: reqParams})
      axios.get(`${origin_}/backend/sale`, {params: this.user})
          .then(res => {
            // let resData = this.$resCommon.getResData(res)
            let resData = res.data.data;
            // console.log(resData);
            this.imgList = resData.img;
            this.copywriting = resData.copywriting;
            this.spread = resData.spread;
            this.to_income_text = resData.to_income_text;
            this.to_poster_text = resData.to_poster_text;
            this.title = resData.title;
          })
    },

    //图片跳转
    link(url) {
      if (url) {
        location.href = url
      }
    },

    //复制文本到剪贴板
    copyLink() {
      this.isShow = true
      setTimeout(() => {
        this.isShow = false
      }, 2000)
      let text = document.getElementById('target').innerText
      this.$copyText(text)
          .then(() => {
            console.log('复制成功');
          })
          .catch(() => {
            console.log('复制失败');
          })
    },
    copyText() {
      this.isShow = true
      setTimeout(() => {
        this.isShow = false
      }, 2000)
      let text = document.getElementById('target2').innerText
      this.$copyText(text)
          .then(() => {
            console.log('复制成功');
          })
          .catch(() => {
            console.log('复制失败');
          })
    },
  }
}
</script>

<style scoped>
/* .v-application--wrap div {
  line-height: 1.5;
} */
.sale-img {
  width: 100%;
  object-fit: contain;
  display: block;
  margin: 0 auto;
}

.content {
  line-height: 1;
  font-size: 16px;
  text-align: center;
}

.content > div {
  margin: 20px auto;
}

.text {
  line-height: 1;
  padding: 3px 15px;
  word-break: break-all;
  display: none;
}

.button {
  display: block;
  margin: 10px auto;
  line-height: 1.8;
  padding: 0 8px;
  background-color: #409eff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
}

.tootip {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1.5;
  padding: 6px 10px;
  width: 250px;
  margin: 15px auto 5px;
  color: #ffffff;
  background-color: black;
  border-radius: 5px;
  transition: all 2s;
  opacity: 0;
  font-size: 16px;
  text-align: center;
}

.show {
  opacity: 1 !important;
}

/* .tootip::after {
  position: absolute;
  content: "";
  top: -17px;
  left: 50%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  border-left: 10px solid transparent;
} */
</style>